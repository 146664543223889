<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title" v-if="type === 'edit'">
                <p>序号：{{xuhao}}</p>
            </div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="日期">
                            <a-input v-model:value="riqi" placeholder="请输入日期"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="用水量">
                            <a-input v-model:value="yongshuiliang" placeholder="请输入用水量"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="水费">
                            <a-input v-model:value="shuifei" placeholder="请输入水费"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="税率">
                            <a-input v-model:value="shuilv" placeholder="请输入税率"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="税额">
                            <a-input v-model:value="shuie" placeholder="请输入税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="备注">
                            <a-input v-model:value="beizhu" placeholder="请输入备注"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="onSave">保存</a-button>
            <a-button type="primary" @click="$router.back()">取消</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'loginType',
            'isAuth',
            'orgId',
            'y'
        ])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            id: '',
            xuhao: '',
            type: '',
            riqi: '',
            yongshuiliang: '',
            shuifei: '',
            shuilv: '',
            shuie: '',
            beizhu: ''
        }
    },
    methods: {
        // 获取水费详情
        getNHSJDetail () {
            this.$store.dispatch('getNHSJDetail', { NENGHAO_DATA_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    let datas = res.pd
                    this.riqi = datas.D_DATE
                    this.yongshuiliang = datas.USECOUNT
                    this.shuifei = datas.USEMONEY
                    this.shuilv = datas.SHUILV
                    this.shuie = datas.SHUIE
                    this.beizhu = datas.REMARK
                } else {
                    this.$message.error('获取水费详情失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (this.type === 'edit') {
                this.onEdit()
            } else if (this.type === 'add') {
                this.onAdd()
            }
        },
        // 添加水费数据
        onAdd () {
            this.$store.dispatch('addNHSJItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                D_DATE: this.riqi,
                USECOUNT: this.yongshuiliang,
                USEMONEY: this.shuifei,
                SHUILV: this.shuilv,
                SHUIE: this.shuie,
                REMARK: this.beizhu,
                CTYPE: 'shuifei',
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('添加成功！')
                    setTimeout(() => {
                        this.$router.push('/ReportForms/SFSJ')
                    }, 1500)
                } else {
                    this.$message.error('添加失败，请稍后重试！')
                }
            })
        },
        // 编辑水费数据
        onEdit () {
            this.$store.dispatch('editNHSJItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                D_DATE: this.riqi,
                USECOUNT: this.yongshuiliang,
                USEMONEY: this.shuifei,
                SHUILV: this.shuilv,
                SHUIE: this.shuie,
                REMARK: this.beizhu,
                CTYPE: 'shuifei',
                NENGHAO_DATA_ID: this.id,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('修改成功！')
                    setTimeout(() => {
                        this.$router.push('/ReportForms/SFSJ')
                    }, 1500)
                } else {
                    this.$message.error('修改失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.xuhao = this.$route.query.num
        this.type = this.$route.query.type
        if (this.type === 'edit') {
            this.getNHSJDetail()
        }
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
    }
}
</style>